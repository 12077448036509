import React, { useEffect } from 'react';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import { DeviceContext } from '../utils/useIsDesktop';
import { GlobalPropsContext } from '../utils/globalProps';
import PropTypes from 'prop-types';
import '../styles/global_includes.scss';
import '../index.scss';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(
                        distDir,
                        'app:///_next'
                    );
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

function MyApp({ Component, pageProps, err }) {
    const globalProps = {
        title: pageProps.componentProps?.title,
        gtmTitle: pageProps.componentProps?.gtmTitle,
        activateCookieScript:
            pageProps.componentProps?.siteSetting?.activateCookieScript,
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const updateAriaLabel = () => {
                const element = document.querySelector('#cookiescript_close');
                if (element) {
                    element.setAttribute('aria-label', 'Stäng cookie-fönster');
                }
            };

            const observerCallback = (mutationsList, observer) => {
                for (let mutation of mutationsList) {
                    if (
                        mutation.type === 'childList' &&
                        mutation.addedNodes.length > 0
                    ) {
                        updateAriaLabel();
                    }
                }
            };

            const observer = new MutationObserver(observerCallback);
            observer.observe(document.body, { childList: true, subtree: true });

            return () => observer.disconnect();
        }
    }, []);

    return (
        <GlobalPropsContext.Provider value={globalProps}>
            <DeviceContext.Provider
                value={pageProps.componentProps?.deviceType}
            >
                <Component {...pageProps} activateCookieScript err={err} />
            </DeviceContext.Provider>
        </GlobalPropsContext.Provider>
    );
}

export default MyApp;

MyApp.propTypes = {
    Component: PropTypes.func,
    pageProps: PropTypes.object,
    err: PropTypes.object,
};
MyApp.defaultProps = {
    Component: () => {},
    pageProps: {},
    err: {},
};
