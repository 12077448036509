import React from 'react';
import useMediaQuery from './useMediaQuery';
import { breakpoints } from '../constants';

const DeviceContext = React.createContext('mobile');

const useIsDesktop = () => {
    const deviceContext = React.useContext(DeviceContext);
    const isDesktop = useMediaQuery({
        query: `(min-width: ${breakpoints.l}px)`,
        options: { defaultMatches: deviceContext === 'desktop' },
    });

    return isDesktop;
};

export { DeviceContext };
export default useIsDesktop;
