const breakpoints = {
    xs: 375,
    s: 768,
    m: 1020,
    l: 1280,
    xl: 1440,
    xxl: 1680,
};

export default breakpoints;
